import $ from 'jquery';

import Cookie from '../classes/Cookie';

export default class CookieAccept {
    private cookieBox = null as any;

    constructor() {
        this.cookieBox = $('.cookie');
        $('#disagree').on('click', this.hideCookieBox);
        $('#agree').on('click', this.hideCookieBox);
    }

    hideCookieBox = () => {
        this.cookieBox.remove();
        Cookie.set('cookie_accept', true);
    };
}
