export default class LocationFilter {
    locationSearch: HTMLElement;
    input: HTMLInputElement;
    locationsList: HTMLUListElement;
    form: any;

    constructor(input: HTMLInputElement, form?: HTMLFormElement) {
        this.input = input;
        this.locationSearch = document.createElement('div');
        
        this.locationsList = document.createElement('ul');

        this.locationSearch.appendChild(this.locationsList);
        this.locationSearch.classList.add('location__list');
        const parent: any = this.input.parentNode;
        parent.appendChild(this.locationSearch);
        this.form = form;

        this.searchEvent();
        this.closeLocationList();
    }

    closeLocationList = () => {
        window.onclick = (event: any) => {
            if (event.target !== this.locationSearch && event.target !== this.input) {
                this.locationSearch.classList.remove('open');
            }
        };
    };

    searchEvent = () => {
        this.input.addEventListener('keyup', () => {
            if (this.input.value.length >= 3) {
                setTimeout(() => this.searchLocation(this.input.value), 300);
            } else {
                this.resetLocationsList();
            }
        });
    };

    searchLocation = (location: string) => {
        const url: any = new URL(window.location.origin + '/api/locations');
        url.searchParams.append('query', location);
        fetch(url)
            .then(response => response.json())
            .then((locations: any) => {
                this.createLocationsList(locations);
            });
    };

    createLocationsList = (locations: any) => {
        this.resetLocationsList();
        locations.forEach((location: any) => {
            const locationLi = document.createElement('li');
            locationLi.innerHTML = location.name;
            this.locationsList.appendChild(locationLi);
            this.changeLocationEvent(locationLi);
        });
        this.locationSearch.classList.add('open');
    };

    changeLocationEvent = (location: HTMLLIElement) => {
        location.addEventListener('click', () => {
            this.input.value = location.innerHTML;
            this.locationSearch.classList.remove('open');
            if (this.form) {
                this.form.submit();
            }
        });
    };

    resetLocationsList = () => {
        this.locationSearch.classList.remove('open');
        this.locationsList.innerHTML = '';
    };
}
