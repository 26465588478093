import * as VanillaLazyLoad from 'vanilla-lazyload';

export default class LazyLoad {
    static config = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        elements_selector: '.lazy',
        threshold: 0,
    };

    constructor() {
        document.addEventListener('DOMContentLoaded', LazyLoad.getInstance);
    }

    static getInstance() {
        return new VanillaLazyLoad(LazyLoad.config);
    }
}
