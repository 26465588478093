class Cookie {
    extend = (...arg: any[]) => {
        const result = {} as any;
        for (let i = 0; i < arg.length; i++) {
            const attributes = arg[i];
            for (const key in attributes) {
                if (Object.prototype.hasOwnProperty.call(attributes, key)) {
                    result[key] = attributes[key];
                }
            }
        }
        return result;
    };

    get = (key: string, json = false) => {
        if (typeof document === 'undefined') {
            return;
        }

        const jar = {} as any;
        const cookies = document.cookie ? document.cookie.split('; ') : [];

        for (let i = 0; i < cookies.length; i++) {
            const parts = cookies[i].split('=');
            let cookie = parts.slice(1).join('=');

            if (!json && cookie.charAt(0) === '"') {
                cookie = cookie.slice(1, -1);
            }

            try {
                const name = this.decode(parts[0]);
                cookie = this.decode(cookie);
                if (json) {
                    try {
                        cookie = JSON.parse(cookie);
                    } catch (e) {
                        console.error(e);
                    }
                }
                jar[name] = cookie;
                if (key === name) {
                    break;
                }
            } catch (e) {
                console.error(e);
            }
        }

        return key ? jar[key] : jar;
    };

    set = (key: string, value: any, attributes = {} as any) => {
        if (typeof document === 'undefined') {
            return;
        }

        attributes = this.extend(
            {
                path: '/',
                expires: 1,
            },
            attributes,
        );

        if (typeof attributes.expires === 'number') {
            attributes.expires = new Date(new Date().getTime() + attributes.expires * 864e5);
        }

        // We're using "expires" because "max-age" is not supported by IE
        attributes.expires = attributes.expires ? attributes.expires.toUTCString() : '';

        try {
            const result = JSON.stringify(value);
            if (/^[{[]/.test(result)) {
                value = result;
            }
        } catch (e) {
            console.error(e);
        }

        value = encodeURIComponent(String(value)).replace(
            /%(23|24|26|2B|3A|3C|3E|3D|2F|3F|40|5B|5D|5E|60|7B|7D|7C)/g,
            decodeURIComponent,
        );

        key = encodeURIComponent(String(key))
            .replace(/%(23|24|26|2B|5E|60|7C)/g, decodeURIComponent)
            .replace(/[()]/g, escape);

        let stringifiedAttributes = '';
        for (const attributeName in attributes) {
            if (Object.prototype.hasOwnProperty.call(attributes, attributeName)) {
                if (!attributes[attributeName]) {
                    continue;
                }
                stringifiedAttributes += '; ' + attributeName;
                if (attributes[attributeName] === true) {
                    continue;
                }
                stringifiedAttributes += '=' + attributes[attributeName].split(';')[0];
            }
        }

        return (document.cookie = `${key}=${value}${stringifiedAttributes}`);
    };
    remove = (key: string, attributes = {}) => {
        this.set(
            key,
            '',
            this.extend(attributes, {
                expires: -1,
            }),
        );
    };

    decode = (str: string) => {
        return str.replace(/(%[0-9A-Z]{2})+/g, decodeURIComponent);
    };
}

export default new Cookie() as Cookie;
