import cookie from './Cookie';

class Storage {
    private KEY = '_user';

    private cookie = cookie;

    constructor() {
        this.syncLocalStorageAndCookie(true);
    }

    getData = (key: string) => {
        this.syncLocalStorageAndCookie();
        return this.getLocal(key);
    };

    setData = (key: string, value: any) => {
        this.syncLocalStorageAndCookie();
        const local = this.getLocalObject();
        local[key] = value;

        localStorage.setItem(this.KEY, JSON.stringify(local));
        this.cookie.set(this.KEY, JSON.stringify(local));
    };

    syncLocalStorageAndCookie = (overrideLocalStorage = false) => {
        const cookies = this.getCookieObject();
        const local = this.getLocalObject();
        const storage1 = overrideLocalStorage ? local : cookies;
        const storage2 = overrideLocalStorage ? cookies : local;

        const sync = {} as any;

        for (const key in storage1) {
            if (Object.prototype.hasOwnProperty.call(storage1, key)) {
                sync[key] = storage1[key];
            }
        }
        for (const key in storage2) {
            if (Object.prototype.hasOwnProperty.call(storage2, key)) {
                sync[key] = storage2[key];
            }
        }

        localStorage.setItem(this.KEY, JSON.stringify(sync));
        this.cookie.set(this.KEY, JSON.stringify(sync));
    };

    private getLocal = (key: string) => {
        const local = this.getLocalObject();
        if (Object.prototype.hasOwnProperty.call(local, key)) {
            return local[key];
        }
        return undefined;
    };

    private getLocalObject = () => {
        try {
            return JSON.parse(localStorage.getItem(this.KEY) || '');
        } catch (e) {
            return {};
        }
    };

    private getCookieObject = () => {
        try {
            return JSON.parse(this.cookie.get(this.KEY));
        } catch (e) {
            return {};
        }
    };
}

export default new Storage() as Storage;
