import Categories, {CategoryMain} from '../Categories';

export default class CategoryFilter {
    categoryList: HTMLElement;
    select: HTMLSelectElement;
    placeholder: any;
    categoryUl: HTMLUListElement;
    form?: any;

    constructor(select: HTMLSelectElement, form?: any) {
      
        this.categoryList = document.createElement('div');
        this.categoryList.classList.add('category__list');
        this.form = form;

        this.categoryUl = document.createElement('ul');
        this.categoryList.appendChild(this.categoryUl);
        this.select = select;

        this.select.style.display = 'none';
        const parent: any = this.select.parentNode;
        console.log(parent);
        this.placeholder = document.querySelector('.placeholder--select');
        parent.appendChild(this.categoryList);
        this.getCategories();
        this.openCategoryList();
        this.closeCategoryList();
    }

    openCategoryList = () => {
        if (this.placeholder) {
            this.placeholder.addEventListener('click', () => {
                this.categoryList.classList.toggle('open');
            });
        }
    };

    closeCategoryList = () => {
        window.onclick = (event: any) => {
            if (event.target !== this.categoryList && event.target !== this.placeholder) {
                this.categoryList.classList.remove('open');
            }
        };
    };

    createCategory = (category: CategoryMain, parentElement: HTMLElement) => {
        const li: any = document.createElement('li');
        const language = document.documentElement.lang;
        if (language === 'ar') {
            li.innerHTML = `<span>${category.nameAr}</span>`;
        }else{
            li.innerHTML = `<span>${category.name}</span>`;
        }
        li.setAttribute('data-name', category.slug);
        parentElement.appendChild(li);
        return li;
    };

    setOption = (value: any) => {
        const options = this.select.options;
        for (let i = 0, optionsLength = options.length; i < optionsLength; i++) {
            if (options[i].value === value) {
                this.select.selectedIndex = i;
                if (this.form) {
                    this.form.submit();
                }
            }
        }
    };

    changeCategory = (category: HTMLElement) => {
        const name: any = category.querySelector('span');
        if (name) {
            name.addEventListener('click', () => {
                const dataId: any = category.getAttribute('data-name');
                this.setOption(dataId);
                this.placeholder.innerHTML = name.innerHTML;
                this.placeholder.classList.remove('empty');
                this.categoryList.classList.remove('open');
            });
        }
    };

    createCategoriesList = (categories: CategoryMain[]) => {
        categories.forEach((category: CategoryMain) => {
            const categoryLi = this.createCategory(category, this.categoryUl);
            this.changeCategory(categoryLi);
            if (category.subcategories.length > 0) {
                const subcategoryList: any = document.createElement('ul');
                categoryLi.appendChild(subcategoryList);
                category.subcategories.forEach((subcategory: any) => {
                    const subcategoryLi = this.createCategory(subcategory, subcategoryList);
                    this.changeCategory(subcategoryLi);
                });
            }
        });
    };

    getCategories = () => {
        Categories.getCategories(this.createCategoriesList);
    };
}
