import $ from 'jquery';

export default class EventStickyInfo {
    constructor() {
        const stickyWrapper = $('.sticky-wrapper');
        if (stickyWrapper.length) {
            const stickyBox = stickyWrapper.find('.event--info');
            const offset = stickyWrapper.offset();
            if (offset) {
                stickyBox.css({top: offset.top});
            }
        }
    }
}
