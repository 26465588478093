import $ from 'jquery';

export default class EventGallery {
    slider: any;
    nav: any;

    constructor() {
        this.nav = $('.slider-nav');
        this.slider = $('.slider-for');
        if (this.nav.length && this.slider.length) {
            this.initSlider();
        }
    }

    initSlider = () => {
        this.slider.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            asNavFor: '.slider-nav',
            adaptiveHeight: true,
        });
        this.nav.slick({
            slidesToShow: this.getSlideToShow(),
            slidesToScroll: 2,
            asNavFor: '.slider-for',
            dots: false,
            arrows: false,
            focusOnSelect: true,
            variableWidth: true,
            infinite: true,
        });
    };

    getSlideToShow = () => {
        const slideToShow = Math.floor(this.nav.width() / 120);
        const count = this.nav.find('img').length;
        return slideToShow < count ? slideToShow : count;
    };
}
