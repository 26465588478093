import $ from 'jquery';

export default class ConfirmSubmit {
    private formClass = '.confirm-submit';
    private form = $(this.formClass);
    private formSubmit = false;

    constructor() {
        if (this.form.length) {
            this.form.data('init-form-data', this.form.serialize());
            this.form.on('submit', () => {
                this.formSubmit = true;
            });
            window.onbeforeunload = this.handlePageReload;
        }
    }

    handlePageReload = () => {
        if (this.formSubmit) {
            return;
        }
        const initValue = this.form.data('init-form-data');
        if (initValue != this.form.serialize()) {
            return 'Your changes have not been saved.';
        }
    };
}
