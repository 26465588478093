import $ from 'jquery';

export default class Comment {
    constructor() {
        const doc = $(document);
        doc.on('change', '.auto-resize-textarea textarea', this.resize);
        doc.on('cut', '.auto-resize-textarea textarea', this.delayedResize);
        doc.on('paste', '.auto-resize-textarea textarea', this.delayedResize);
        doc.on('drop', '.auto-resize-textarea textarea', this.delayedResize);
        doc.on('keydown', '.auto-resize-textarea textarea', this.delayedResize);
        doc.on('click', '.fos_expand_replay', this.toggleExpand);
        $('.auto-resize-textarea textarea').trigger('change');
    }

    resize = (e: any) => {
        e.currentTarget.style.height = 'auto';
        e.currentTarget.style.height = `${e.currentTarget.scrollHeight + 30}px`;
    };

    delayedResize = (e: any) => {
        window.setTimeout(this.resize, 0, e);
    };

    toggleExpand = (e: any) => {
        $(e.currentTarget)
            .parents('.fos_comment_comment_show')
            .toggleClass('fos_open_replay');
    };
}
