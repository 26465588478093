import {Chart, InteractionMode} from 'chart.js';
import $ from 'jquery';

import chartBgUrl from '../../image/chart/bg.png';

export default class Statistic {
    chartBgImage: HTMLImageElement | any;
    chartBgPattern: any;

    constructor() {
        if ($('.event-charts').length) {
            this.loadImage(this.initModule);
        }
    }

    initModule = () => {
        this.createPattern();
        this.initEvent();
        this.loadChart($('a[data-toggle="tab"].active'), false);
    };

    changeRange = (event: any) => {
        const select = $(event.currentTarget);
        const [from, to] = (select.val() + '').split(':');
        const tab = $('#nav-' + select.data('id'));

        tab.data({
            from: from,
            to: to,
            init: 'changed',
        });
        this.loadChart(tab, true);
    };

    loadImage = (callback: any) => {
        this.chartBgImage = new Image();
        this.chartBgImage.onload = callback;
        this.chartBgImage.src = chartBgUrl;
    };

    createPattern = () => {
        const ctx = document.createElement('canvas').getContext('2d');
        if (ctx) {
            this.chartBgPattern = ctx.createPattern(this.chartBgImage, 'repeat');
        }
    };

    initEvent = () => {
        $('a[data-toggle="tab"]').on('shown.bs.tab', this.tabShown);
        $('.statistics-range').on('change', this.changeRange);
    };

    tabShown = (event: any) => {
        const target = $(event.target);
        if (!target.data('init')) {
            this.loadChart(target, false);
        } else if (target.data('init') === 'changed') {
            this.loadChart(target, true);
        }
    };

    renderChar = (element: any) => (data: any) => {
        const tab = this.getContentElement(element.data('id'));
        const canvas = document.createElement('canvas') as any;
        tab.addClass('ready');
        tab.append(canvas);
        const ctx = canvas.getContext('2d');
        const chart = new Chart(ctx, this.chartConfig(data, element.data('label')) as any);
        element.data({
            init: true,
            chart: chart,
        });
    };

    updateChar = (element: any) => (result: any) => {
        const tab = this.getContentElement(element.data('id'));
        const chart = element.data('chart');
        chart.data.labels = this.resultToLabels(result);
        chart.data.datasets[0].data = this.resultToData(result);
        chart.update();
        tab.addClass('ready');
        element.data({init: true});
    };

    resultToLabels = (result: any) => {
        return result.map((item: any) => item.date);
    };

    resultToData = (result: any) => {
        return result.map((item: any) => item.activities);
    };

    getContentElement = (id: any) => {
        return $('#' + id).find('.statistic-chart-content');
    };

    loadChart = (element: any, update: boolean) => {
        this.getContentElement(element.data('id')).removeClass('ready');
        const from = element.data('from');
        const to = element.data('to');
        const url = element.data('url');
        this.getData(this.parseUrl(url, from, to)).then(update ? this.updateChar(element) : this.renderChar(element));
    };

    parseUrl = (url: string, from: string, to: string) => {
        return url.replace('__START__', from).replace('__END__', to);
    };

    chartConfig = (result: any, label: string) => {
        return {
            type: 'line',
            data: {
                labels: this.resultToLabels(result),
                datasets: [
                    {
                        label,
                        backgroundColor: this.chartBgPattern,
                        hoverBackgroundColor: this.chartBgPattern,
                        pointBackgroundColor: '#fff',
                        pointHoverBackgroundColor: '#fff',
                        lineTension: 0,
                        borderColor: '#4a4a4a',
                        data: this.resultToData(result),
                        fill: true,
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
                title: {
                    display: false,
                },
                tooltips: {
                    mode: 'index' as InteractionMode,
                    intersect: false,
                    xPadding: 20,
                    yPadding: 10,
                    cornerRadius: 3,
                    caretPadding: 5,
                    titleFontSize: 10,
                    titleFontStyle: 'normal',
                    titleFontColor: '#909090',
                    bodyFontSize: 12,
                    bodyFontColor: '#4a4a4a',
                    displayColors: false,
                    backgroundColor: '#fff',
                    borderWidth: 1,
                    borderColor: '#ededed',
                },
                hover: {
                    mode: 'nearest' as InteractionMode,
                    intersect: true,
                },
                elements: {
                    point: {
                        radius: 4,
                        hoverRadius: 6,
                        pointStyle: 'circle',
                        borderWidth: 3,
                        hoverBorderWidth: 3,
                    },
                },
                scales: {
                    xAxes: [
                        {
                            gridLines: {
                                display: false,
                                drawBorder: false,
                            },
                            type: 'time',
                            time: {
                                unit: 'day',
                                tooltipFormat: 'll',
                            },
                            ticks: {
                                fontColor: '#909090',
                                fontSize: 14,
                                fontFamily: 'Poppins',
                                padding: 20,
                            },
                        },
                    ],
                    yAxes: [
                        {
                            gridLines: {
                                color: '#ededed',
                                drawBorder: false,
                                zeroLineColor: 'transparent',
                            },
                            ticks: {
                                callback: (val: any) => (Number.isInteger(val) ? val : null),
                                fontColor: '#909090',
                                fontSize: 12,
                                fontFamily: 'Poppins',
                                padding: 12,
                                beginAtZero: true,
                            },
                        },
                    ],
                },
            },
        };
    };

    getData = (url = '') => {
        return fetch(url, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
            },
            redirect: 'follow', // manual, *follow, error
            referrer: 'no-referrer', // no-referrer, *client
        }).then(response => response.json()); // parses response to JSON
    };
}
