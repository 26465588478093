import Storage from '../classes/Storage';

type GetMapboxTokenResponse = {
    token: string;
    expiresAt: string;
};

const getToken = () => fetch('/api/mapbox-token').then(response => response.json() as Promise<GetMapboxTokenResponse>);

const isExpired = (expirationDate: Date) => {
    const localeExpirationDate = new Date(expirationDate.toLocaleString());
    return !(localeExpirationDate.getTime() - new Date().getTime() > 0);
};

export default async (): Promise<string> => {
    const token = Storage.getData('mapbox.token');
    const expiredAt = Storage.getData('mapbox.expiredAt');
    if (token && expiredAt) {
        if (!isExpired(expiredAt)) {
            return token;
        }
    }
    return getToken().then(({token, expiresAt}) => {
        Storage.setData('mapbox.token', token);
        Storage.setData('mapbox.expiredAt', expiresAt);
        return token;
    });
};
