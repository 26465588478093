import mapboxgl, {Map, MapMouseEvent, MapLayerMouseEvent, GeoJSONSource} from 'mapbox-gl';
import {Point} from 'geojson';

import * as hoverIntent from 'hoverintent';

import mapboxToken from '../utils/mapboxToken';
import boundaryPoints from '../utils/boundaryPoints';

export default class ResultMap {
    eventLatLan: {[event: string]: [number, number]} = window.eventLatLan;
    mapContainerId = 'result-map';
    toggleMapClassName = 'toggle-map';
    eventBoxClassName = 'event-box';
    map?: Map;

    constructor() {
        if (document.getElementById(this.mapContainerId)) {
            mapboxToken().then(token => {
                this.renderMap(token);
                this.addEventBoxEvent();
                this.addToggleMapEvent();
            });
        }
    }

    addClustersLayer = () => {
        this.map = this.map as Map;
        this.map.addLayer({
            id: 'clusters',
            type: 'circle',
            source: 'events',
            filter: ['has', 'point_count'],
            paint: {
                'circle-color': '#1b1b1b',
                'circle-radius': ['step', ['get', 'point_count'], 15, 5, 20, 10, 25],
            },
        });
        this.map.addLayer({
            id: 'cluster-count',
            type: 'symbol',
            source: 'events',
            filter: ['has', 'point_count'],
            layout: {
                'text-field': '{point_count_abbreviated}',
                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                'text-size': 12,
            },
            paint: {
                'text-color': '#fff',
            },
        });
    };

    addEventBoxEvent = () => {
        document.querySelectorAll(`.${this.eventBoxClassName}`).forEach(element => {
            hoverIntent(
                element,
                () => this.handleHoverIntent(element as HTMLElement),
                () => undefined,
            );
        });
    };

    addEventPopup = (eventId: number, latLan: [number, number]) => {
        new mapboxgl.Popup({
            closeButton: false,
        })
            .setLngLat(latLan)
            .setHTML((document.querySelector(`.${this.eventBoxClassName}-${eventId}`) as HTMLElement).outerHTML)
            .addTo(this.map as Map);
    };

    addEventsSource = () => {
        (this.map as Map).addSource('events', {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: Object.keys(this.eventLatLan).map(key => {
                    return {
                        type: 'Feature',
                        properties: {
                            eventId: key,
                        },
                        geometry: {
                            type: 'Point',
                            coordinates: this.eventLatLan[key] as [number, number],
                        },
                    };
                }),
            },
            cluster: true,
            clusterMaxZoom: 14,
            clusterRadius: 50,
        });
    };

    addToggleMapEvent = () => {
        document.querySelectorAll(`.${this.toggleMapClassName}`).forEach(element => {
            element.addEventListener('click', () => {
                document.body.classList.toggle('open-map');
                (this.map as Map).resize();
                this.fitBounds();
            });
        });
    };

    addUnclusteredPointsLayer = () => {
        (this.map as Map).addLayer({
            id: 'unclustered-points',
            type: 'circle',
            source: 'events',
            filter: ['!', ['has', 'point_count']],
            paint: {
                'circle-color': 'transparent',
                'circle-stroke-color': '#1b1b1b',
                'circle-radius': 8,
                'circle-stroke-width': 4,
            },
        });
    };

    fitBounds = () => {
        (this.map as Map).fitBounds(boundaryPoints(Object.values(this.eventLatLan)), {
            padding: 50,
            maxZoom: 15,
            speed: 3,
        });
    };

    handleClusterClick = (event: MapMouseEvent) => {
        this.map = this.map as Map;
        const features = this.map.queryRenderedFeatures(event.point, {
            layers: ['clusters'],
        });
        const clusterId = features[0].properties?.cluster_id;
        if (clusterId) {
            (this.map.getSource('events') as GeoJSONSource).getClusterExpansionZoom(
                clusterId,
                (error: any, zoom: number) => {
                    if (error) {
                        return;
                    }
                    (this.map as Map).easeTo({
                        center: (features[0].geometry as Point).coordinates as [number, number],
                        zoom: zoom,
                    });
                },
            );
        }
    };

    handleHoverIntent = (element: HTMLElement) => {
        const id = element.dataset.id as string;
        (this.map as Map).flyTo({
            center: window.eventLatLan[id],
            speed: 3,
        });
    };

    handleUnclusteredPointClick = (event: MapLayerMouseEvent) => {
        if (event.features) {
            const eventId = event.features[0].properties?.eventId;
            if (eventId) {
                const latLan = (event.features[0].geometry as Point).coordinates.slice() as [number, number];
                while (Math.abs(event.lngLat.lng - latLan[0]) > 180) {
                    latLan[0] += event.lngLat.lng > latLan[0] ? 360 : -360;
                }
                this.addEventPopup(eventId, latLan);
            }
        }
    };

    renderMap = (token: string) => {
        mapboxgl.accessToken = token;
        this.map = new mapboxgl.Map({
            container: this.mapContainerId,
            style: 'mapbox://styles/mapbox/light-v10',
            center: Object.values(this.eventLatLan)[0],
            zoom: 3,
            bounds: boundaryPoints(Object.values(this.eventLatLan)),
        });
        this.map.on('load', () => {
            this.map = this.map as Map;
            this.addEventsSource();
            this.addClustersLayer();
            this.addUnclusteredPointsLayer();
            this.fitBounds();
            this.map.on('click', 'clusters', this.handleClusterClick);
            this.map.on('click', 'unclustered-points', this.handleUnclusteredPointClick);
            this.styleMapCursors();
        });
    };

    styleMapCursors = () => {
        this.map = this.map as Map;
        this.map.on('mouseenter', 'clusters', () => {
            (this.map as Map).getCanvas().style.cursor = 'pointer';
        });
        this.map.on('mouseleave', 'clusters', () => {
            (this.map as Map).getCanvas().style.cursor = '';
        });
        this.map.on('mouseenter', 'unclustered-points', () => {
            (this.map as Map).getCanvas().style.cursor = 'pointer';
        });
        this.map.on('mouseleave', 'unclustered-points', () => {
            (this.map as Map).getCanvas().style.cursor = '';
        });
    };
}
