const AlertVariant = {
    Success: 'success',
    Warning: 'Warning',
    Error: 'Error',
};

export default class Alert {
    constructor(message: string, type = AlertVariant.Success) {
        const list = document.querySelector('.flash-message');``
        if (list) {
            list.appendChild(this.getMessageElement(type, message));
        }
    }

    getMessageElement = (type: string, content: string) => {
        const message = document.createElement('div');
        const inner = document.createElement('div');
        const close = document.createElement('div');

        message.className = `message ${type}`;
        inner.className = `message-content`;
        inner.innerHTML = content;
        close.className = `close-block`;
        close.addEventListener('click', () => {
            if (close.parentNode && close.parentNode.parentNode) {
                close.parentNode.parentNode.removeChild(close.parentNode);
            }
        });
        message.appendChild(inner);
        message.appendChild(close);
        return message;
    };
}
