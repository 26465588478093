import $ from 'jquery';

import {getGlobal} from '../../react/utils/Window';
import Alert from './Alert';

export default class FosComment {
    baseUrl: string;
    private threadContainer: JQuery<HTMLElement>;

    constructor() {
        this.threadContainer = $('#fos_comment_thread');
        this.baseUrl = getGlobal('fosCommentThreadApiBaseUrl');

        const threadId = getGlobal('fosCommentThreadId', false);
        if (threadId) {
            this.getThreadComments(threadId);
        }

        if ($('span.fos-comment-count').length > 0) {
            this.loadCommentCounts();
        }

        this.initializeListeners();
    }

    post = (url: string, data: any, success: any, error?: any, complete?: any) => {
        // Wrap the error callback to match return data between jQuery and easyXDM
        const wrappedErrorCallback = (response: any) => {
            if ('undefined' !== typeof error) {
                error(response.responseText, response.status);
            }
        };
        const wrappedCompleteCallback = (response: any) => {
            if ('undefined' !== typeof complete) {
                complete(response.responseText, response.status);
            }
        };
        $.post(url, data, success)
            .fail(wrappedErrorCallback)
            .always(wrappedCompleteCallback);
    };

    get = (url: string, data: any, success: any, error?: any) => {
        // Wrap the error callback to match return data between jQuery and easyXDM
        const wrappedErrorCallback = (response: any) => {
            if ('undefined' !== typeof error) {
                error(response.responseText, response.status);
            }
        };
        $.get(url, data, success).fail(wrappedErrorCallback);
    };

    getThreadComments = (identifier: any, permalink?: any) => {
        const evt = {
            identifier,
            params: {
                permalink: encodeURI(permalink || window.location.href),
                view: getGlobal('fosCommentThreadView'),
            },
        };

        this.get(
            this.baseUrl + '/' + encodeURIComponent(identifier) + '/comments',
            evt.params,
            // success
            (data: any) => {
                this.threadContainer.html(data);
                this.threadContainer.attr('data-thread', identifier);
            },
        );
    };

    serializeObject = (obj: any) => {
        const o = {} as any;
        const a = $(obj).serializeArray();
        $.each(a, (el, obj) => {
            if (o[obj.name] !== undefined) {
                if (!o[obj.name].push) {
                    o[obj.name] = [o[obj.name]];
                }
                o[obj.name].push(obj.value || '');
            } else {
                o[obj.name] = obj.value || '';
            }
        });
        return o;
    };

    initializeListeners = () => {
        this.threadContainer.on('submit', 'form.fos_comment_comment_new_form', e => {
            const that = $(e.currentTarget);
            const serializedData = this.serializeObject(e.currentTarget);

            e.preventDefault();

            that.addClass('fos_loading');

            this.post(
                e.currentTarget.action,
                serializedData,
                // success
                (data: any) => {
                    that.removeClass('fos_loading');
                    this.appendComment(data, that);
                    new Alert('New comment was added');
                },
                // error
                (data: any) => {
                    that.removeClass('fos_loading');
                    const parent = that.parent();
                    parent.after(data);
                    parent.remove();
                },
            );
        });

        this.threadContainer.on('click', '.fos_comment_comment_reply_show_form', e => {
            const that = $(e.currentTarget);
            const formData = that.data();

            if (that.closest('.fos_comment_comment_reply').hasClass('fos_comment_replying')) {
                return that;
            }

            this.get(formData.url, {parentId: formData.parentId}, (data: any) => {
                that.closest('.fos_comment_comment_reply').addClass('fos_comment_replying');
                that.after(data);
            });
        });

        this.threadContainer.on('click', '.fos_comment_comment_reply_cancel', e => {
            const formHolder = $(e.currentTarget).closest('.fos_comment_comment_form_holder');

            formHolder.closest('.fos_comment_comment_reply').removeClass('fos_comment_replying');
            formHolder.remove();
        });

        this.threadContainer.on('click', '.fos_comment_comment_edit_show_form', e => {
            const that = $(e.currentTarget);
            const formData = that.data();

            this.get(formData.url, {}, (data: any) => {
                const commentBody = $(formData.container);

                // save the old comment for the cancel function
                commentBody.data('original', commentBody.html());

                // show the edit form
                commentBody.html(data);
            });
        });

        this.threadContainer.on('submit', 'form.fos_comment_comment_edit_form', e => {
            const that = $(e.currentTarget);

            this.post(
                e.currentTarget.action,
                this.serializeObject(e.currentTarget),
                (data: any) => {
                    this.editComment(data);
                    new Alert('Comment was edited');
                },
                (data: any) => {
                    const parent = that.parent();
                    parent.after(data);
                    parent.remove();
                },
            );

            e.preventDefault();
        });

        this.threadContainer.on('click', '.fos_comment_comment_edit_cancel', e => {
            this.cancelEditComment($(e.currentTarget).parents('.fos_comment_comment_body'));
        });

        this.threadContainer.on('click', '.fos_comment_comment_vote', e => {
            const that = $(e.currentTarget);
            const formData = that.data();

            // Get the form
            this.get(formData.url, {}, (data: any) => {
                // Post it
                const form = $($.trim(data)).children('form')[0];
                const formData = $(form).data();

                this.post(e.currentTarget.action, this.serializeObject(form), (data: any) => {
                    $('#' + formData.scoreHolder).html(data);
                });
            });
        });

        this.threadContainer.on('click', '.fos_comment_comment_remove', e => {
            const el = $(e.currentTarget);
            const formData = el.data();

            // Get the form
            this.get(formData.url, {}, (data: any) => {
                // Post it
                const form = $($.trim(data)).children('form')[0] as HTMLFormElement;

                this.post(form.action, this.serializeObject(form), (data: any) => {
                    this.editComment(data);
                });
            });
        });

        this.threadContainer.on('click', '.fos_comment_thread_commentable_action', e => {
            const formData = $(e.currentTarget).data();

            // Get the form
            this.get(formData.url, {}, (data: any) => {
                // Post it
                const form = $($.trim(data)).children('form')[0] as HTMLFormElement;

                this.post(form.action, this.serializeObject(form), (data: any) => {
                    const form = $($.trim(data)).children('form')[0];
                    const threadId = $(form).data().fosCommentThreadId;

                    this.getThreadComments(threadId);
                });
            });
        });
    };

    appendComment = (commentHtml: string, form: any) => {
        const formData = form.data();

        if ('' !== formData.parent) {
            $('#fos_comment_' + formData.parent).addClass('fos_open_replay');
            const replyButtonHolder = form.closest('.fos_comment_comment_reply');

            const commentElement = form.closest('.fos_comment_comment_show').find('.fos_comment_comment_replies');

            replyButtonHolder.removeClass('fos_comment_replying');

            commentElement.append(commentHtml);
        } else {
            form.after(commentHtml);
            form.children('.fos_comment_form_errors').remove();
        }
        form = $(form[0]);
        form[0].reset();
    };

    editComment = (html: string) => {
        const commentHtml = $($.trim(html));
        const commentHtmlId = commentHtml.attr('id') || '';
        const [, commentlId = ''] = commentHtmlId.match(/.+_(\d+)/);

        const originalCommentBody = $('#' + commentHtmlId).find('#fos_comment_box_' + commentlId);

        originalCommentBody.html(commentHtml.find('.fos_comment_box').html());
    };

    cancelEditComment = (commentBody: JQuery<Element>) => {
        commentBody.html(commentBody.data('original'));
    };

    loadCommentCounts = () => {
        const threadIds = [] as string[];
        const commentCountElements = $('span.fos-comment-count');

        commentCountElements.each((i, elem) => {
            const threadId = $(elem).data('fosCommentThreadId');

            if (threadId) {
                threadIds.push(threadId);
            }
        });

        this.get(this.baseUrl + '.json', {ids: threadIds}, (data: any) => {
            // easyXdm doesn't always serialize
            if (typeof data != 'object') {
                data = $.parseJSON(data);
            }

            const threadData = {} as any;

            for (const i in data.threads) {
                if (Object.prototype.hasOwnProperty.call(data.threads, i)) {
                    threadData[data.threads[i].id] = data.threads[i];
                }
            }

            $.each(commentCountElements, (i, el) => {
                const threadId = $(el).data('fosCommentThreadId');
                if (threadId) {
                    this.setCommentCount(el, threadData[threadId]);
                }
            });
        });
    };

    setCommentCount = (elem: HTMLElement, threadObject: any) => {
        if (threadObject === undefined) {
            elem.innerHTML = '0';

            return;
        }

        elem.innerHTML = threadObject.num_comments;
    };
}
