import moment from 'moment';

export const formatDateRange = (startTime: string | Date, endTime: string | Date) => {
    const start = moment(startTime);
    const end = moment(endTime);
    const startDay = start.date();
    const startMonth = start.month();
    const startYear = start.year();
    const endDay = end.date();
    const endMonth = end.month();
    const endYear = end.year();
    if (!start.isValid() || !start.isValid()) {
        return ' – ';
    } else if (startYear !== endYear) {
        return `${start.format('DD-MM-YYYY')} – ${end.format('DD-MM-YYYY')}`;
    } else if (startMonth !== endMonth) {
        return `${start.format('DD MMM')} – ${end.format('DD-MM-YYYY')}`;
    } else if (startDay !== endDay) {
        return `${start.format('DD')} – ${end.format('DD-MM-YYYY')}`;
    } else {
        return end.format('DD-MM-YYYY');
    }
};

export const formatDate = (date: string | Date, format = 'DD-MM-YYYY') => {
    return moment(date).format(format);
};

export const formatPrice = (val: string | number, fill = false) => {
    val = `${val}`
        .replace(',', '.')
        .replace(/[^\d.]/g, '')
        .replace(/\..*/, c => '.' + c.replace(/\./g, ''))
        .replace(/^[0]+([\d]+)+/, '$1');

    if (fill && val.indexOf('.') === -1) {
        val += '.00';
    }

    if (val.charAt(0) === '.') {
        val = '0' + val;
    }

    return val.replace(/\..*/, (str: string) => {
        const end = str.slice(1, 3);
        return '.' + (fill ? (end + '00').slice(0, 2) : end);
    });
};

export const formatNumber = (val: string) => {
    val = val
        .replace(',', '.')
        .replace(/[^\d.]/g, '')
        .replace(/\..*/, c => '.' + c.replace(/\./g, ''))
        .replace(/^[0]+([\d]+)+/, '$1');

    if (val.charAt(0) === '.') {
        val = '0' + val;
    }
    return val;
};

export const formatInteger = (val: string) => {
    return val.replace(/[^\d.]/g, '');
};

export const formatPhoneNumber = (val: string) => {
    return val.replace(/([^+]+)\++/g, '$1').replace(/[^+\d]+/g, '');
};

export default {
    formatDateRange,
    formatPrice,
    formatNumber,
    formatInteger,
    formatPhoneNumber,
};
