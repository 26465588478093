import $ from 'jquery';

export default class Currency {
    currencyForm: any;
    select: any;

    constructor() {
        this.initElement();
        this.initEvent();
    }

    initElement = () => {
        this.currencyForm = $('form[name="currency"]');
        this.select = this.currencyForm.find('select');
    };

    initEvent = () => {
        this.select.change(() => {
            this.currencyForm.submit();
        });
    };
}
