const State: any = {
    FAVOURITE: true,
    UNFAVOURITE: false,
};

export default class LikeEvent {
    button: any;
    state: boolean = State.UNFAVOURITE;
    likeLabel = '';
    likedLabel = '';
    eventId: any;

    constructor() {
        this.button = document.querySelector('button.likeAction');
        if (this.button) {
            this.eventId = this.button.dataset.eventid;
            this.likeLabel = this.button.dataset.likelabel;
            this.likedLabel = this.button.dataset.likedlabel;
            this.checkStatus();
        }
    }

    checkStatus = async () => {
        fetch('/api/favourites?event.id=' + this.eventId)
            .then(response => response.json())
            .then(response => {
                this.state = response.length > 0;
                this.changeButton();
                this.clickEvent();
            });
    };

    callApi = () => {
        const method = this.state ? 'DELETE' : 'POST';
        fetch('/api/favourites/' + this.eventId, {method}).then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            this.state = !this.state;
            this.changeButton();
        });
    };

    clickEvent = () => {
        this.button.addEventListener('click', () => {
            this.callApi();
        });
    };

    changeButton = () => {
        this.button.classList.remove('btn--liked');
        this.button.classList.remove('btn--like');
        this.button.classList.add(this.state ? 'btn--liked' : 'btn--like');
        this.button.style = this.state ? "opacity:0.5;background: none;border: none;outline: none;box-shadow: none;" : "opacity:1;background: none;border: none;outline: none;box-shadow: none;";
        // this.button.innerHTML = this.state ? this.likedLabel : this.likeLabel;
    };
}
