import Formatter from '../../react/utils/Formatter';

export default class NumberFormat {
    constructor() {
        this.initNumberFormat();
        this.initPriceFormat();
        this.initIntFormat();
        this.initPhoneFormat();
    }

    initNumberFormat = () => {
        this.setEvent('.format-number', Formatter.formatNumber);
    };

    initIntFormat = () => {
        this.setEvent('.format-int', Formatter.formatInteger);
    };

    initPriceFormat = () => {
        this.setEvent('.format-price', Formatter.formatPrice);
    };

    initPhoneFormat = () => {
        this.setEvent('.format-phone', Formatter.formatPhoneNumber);
    };

    setEvent = (className: string, formatter: (value: string) => any) => {
        this.on(className, 'input', (evt: Event) => this.format(evt, formatter));
    };
    on = (selector: string, event: string, callback: any) => {
        this.each([...document.querySelectorAll(selector)], (element: HTMLElement) => {
            element.addEventListener(event, callback);
        });
    };

    each = (elements: any[], callback: (element: any, index?: number) => void) => {
        elements.forEach((element, index) => callback(element, index));
    };

    format = (evt: Event, formatter: (value: string) => any) => {
        const target = evt.target as HTMLInputElement;
        target.value = formatter(target.value);
    };
}
