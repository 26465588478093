import mapboxgl, {Map, MapLayerMouseEvent} from 'mapbox-gl';
import {Point} from 'geojson';

import mapboxToken from '../utils/mapboxToken';
import boundaryPoints from '../utils/boundaryPoints';

export default class EventMap {
    eventLatLan: [number, number, number][] = window.eventLatLan;
    mapContainerId = 'event-map';
    viewOnMapClassName = 'view-on-map';
    map?: Map;

    constructor() {
        if (document.getElementById(this.mapContainerId)) {
            mapboxToken().then(token => {
                this.renderMap(token);
                this.addViewOnMapEvent();
            });
        }
    }

    addActivitiesSource = () => {
        (this.map as Map).addSource('activities', {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: Object.values(this.eventLatLan).map(value => {
                    return {
                        type: 'Feature',
                        properties: {
                            eventId: value[0],
                        },
                        geometry: {
                            type: 'Point',
                            coordinates: [value[1], value[2]],
                        },
                    };
                }),
            },
            cluster: true,
            clusterMaxZoom: 15,
            clusterRadius: 50,
        });
    };

    addActivityPopup = (eventId: number, latLan: [number, number]) => {
        new mapboxgl.Popup({
            closeButton: false,
        })
            .setLngLat(latLan)
            .setHTML((document.querySelector(`.activity-box-${eventId}`) as HTMLElement).outerHTML)
            .addTo(this.map as Map);
    };

    addClustersLayer = () => {
        this.map = this.map as Map;
        this.map.addLayer({
            id: 'clusters',
            type: 'circle',
            source: 'activities',
            filter: ['has', 'point_count'],
            paint: {
                'circle-color': '#1b1b1b',
                'circle-radius': ['step', ['get', 'point_count'], 15, 5, 20, 10, 25],
            },
        });
        this.map.addLayer({
            id: 'cluster-count',
            type: 'symbol',
            source: 'activities',
            filter: ['has', 'point_count'],
            layout: {
                'text-field': '{point_count_abbreviated}',
                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                'text-size': 12,
            },
            paint: {
                'text-color': '#fff',
            },
        });
    };

    addUnclusteredPointsLayer = () => {
        (this.map as Map).addLayer({
            id: 'unclustered-points',
            type: 'circle',
            source: 'activities',
            filter: ['!', ['has', 'point_count']],
            paint: {
                'circle-color': 'transparent',
                'circle-stroke-color': '#1b1b1b',
                'circle-radius': 8,
                'circle-stroke-width': 4,
            },
        });
    };

    addViewOnMapEvent = () => {
        document.querySelectorAll(`.${this.viewOnMapClassName}`).forEach(element => {
            element.addEventListener('click', this.handleViewOnMapClick);
        });
    };

    fitBounds = () => {
        (this.map as Map).fitBounds(boundaryPoints(this.eventLatLan.map(latLan => [latLan[1], latLan[2]])), {
            padding: 50,
            maxZoom: 15,
            speed: 3,
        });
    };

    getEventLatLan = (eventId: number): [number, number] => {
        const latLan = this.eventLatLan.find(latLan => latLan[0] === eventId);
        if (latLan) {
            return [latLan[1], latLan[2]];
        } else {
            throw 'Cannot find event coordinates';
        }
    };

    handleUnclusteredPointClick = (event: MapLayerMouseEvent) => {
        if (event.features) {
            const eventId = event.features[0].properties?.eventId;
            if (eventId) {
                const latLan = (event.features[0].geometry as Point).coordinates.slice() as [number, number];
                while (Math.abs(event.lngLat.lng - latLan[0]) > 180) {
                    latLan[0] += event.lngLat.lng > latLan[0] ? 360 : -360;
                }
                this.addActivityPopup(eventId, latLan);
            }
        }
    };

    handleViewOnMapClick = (event: Event) => {
        const eventId = parseInt((event.target as HTMLElement).dataset.id as string);
        (this.map as Map).flyTo({
            center: this.getEventLatLan(eventId),
            speed: 3,
        });
        this.addActivityPopup(eventId, this.getEventLatLan(eventId));
    };

    renderMap = (token: string) => {
        mapboxgl.accessToken = token;
        this.map = new mapboxgl.Map({
            container: this.mapContainerId,
            style: 'mapbox://styles/mapbox/light-v10',
            center: [this.eventLatLan[0][1], this.eventLatLan[0][2]],
            zoom: 15,
        });
        this.map.on('load', () => {
            this.map = this.map as Map;
            this.addActivitiesSource();
            this.addClustersLayer();
            this.addUnclusteredPointsLayer();
            this.fitBounds();
            this.map.on('click', 'unclustered-points', this.handleUnclusteredPointClick);
            this.styleMapCursors();
        });
    };

    styleMapCursors = () => {
        this.map = this.map as Map;
        this.map.on('mouseenter', 'clusters', () => {
            (this.map as Map).getCanvas().style.cursor = 'pointer';
        });
        this.map.on('mouseleave', 'clusters', () => {
            (this.map as Map).getCanvas().style.cursor = '';
        });
        this.map.on('mouseenter', 'unclustered-points', () => {
            (this.map as Map).getCanvas().style.cursor = 'pointer';
        });
        this.map.on('mouseleave', 'unclustered-points', () => {
            (this.map as Map).getCanvas().style.cursor = '';
        });
    };
}
