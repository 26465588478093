import $ from 'jquery';

import Categories, {CategoryMain} from './Categories';

type SelectData = {id: number; text: string};

export default class CategoryPicker {
    constructor(el: any) {
        $(document).on('click', '.btn--closer', this.removeCategory);
        Categories.getCategories(categories => this.createSelect2(el, categories));
    }

    createSelect2 = (element: any, categories: CategoryMain[]) => {
        const flatCategories = categories.reduce(
            (array, element) => [
                ...array,
                {id: element.id, text: element.name},
                ...element.subcategories.map(cat => ({id: cat.id, text: cat.name})),
            ],
            [] as SelectData[],
        );

        const select = $(element);
        select.select2({
            data: flatCategories,
            width: '300px',
            dropdownParent: select.parent().find('.select-category-dropdown'),
        });
        select.on('select2:select', this.selectedEvent);
        select
            .parents('.select-categories')
            .find('.add-new-category')
            .on('click', this.openSelect);
    };

    openSelect = (evt: any) => {
        const select2 = $(evt.target)
            .parents('.select-categories')
            .find('select') as any;
        select2.select2('open');
    };

    selectedEvent = (evt: any) => {
        const select2 = $(evt.target);
        const box = select2.parents('.select-categories');

        if (!this.hasCategory(box, evt.params.data.id)) {
            const addBtn = box.find('.add-new-category');
            this.categoryTag(addBtn, evt.params.data.id, evt.params.data.text).insertBefore(addBtn);
        }
    };

    hasCategory = (box: any, id: any) => {
        return box
            .find('input[type="hidden"]')
            .map((i: any, el: any) => el.value)
            .get()
            .includes(id);
    };

    categoryTag = (addBtn: any, value: any, name: any) => {
        const template = addBtn.data('template');
        return $(template.replace('__NAME__', name).replace('__VALUE__', value));
    };

    removeCategory = (evt: any) => {
        $(evt.currentTarget)
            .parent()
            .remove();
    };
}
