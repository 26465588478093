import CategoryFilter from '../classes/search/CategoryFilter';
import DateFilter from '../classes/search/DateFilter';
import LocationFilter from '../classes/search/LocationFilter';

// import lightpick from 'lightpick';

export default class HomepageSearch {
    sportTypeSelect: HTMLSelectElement | null;
    locationInput: HTMLInputElement | null;

    constructor() {
        this.sportTypeSelect = document.querySelector('.input__sportType select');
        this.locationInput = document.querySelector('.input__locationType input');

        this.initElement();
    }

    initElement = () => {
        this.datePicker();
        this.sportSelect();
        this.locationSelect();
    };

    locationSelect = () => {
        if (this.locationInput) {
            new LocationFilter(this.locationInput);
        }
    };

    sportSelect = () => {
        if (this.sportTypeSelect) {
            new CategoryFilter(this.sportTypeSelect);
        }
    };

    datePicker = () => {
        const dateElement: any = document.querySelector('.input__dateRange');
        if (dateElement) {
            new DateFilter(dateElement);
        }
    };
}
