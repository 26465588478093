import $ from 'jquery';

export default class EditGallery {
    addNewPhoto: any;
    template: any;

    constructor() {
        this.addNewPhoto = $('.gallery-image-form');
        if (this.addNewPhoto.length) {
            this.template = $('.gallery-image-form.empty')
                .clone()
                .wrapAll('<div>')
                .parent()
                .html();
            this.initEvent();
        }
    }

    initEvent = () => {
        $(document).on('change', '.gallery-image-form input', this.selectPhoto);
        $(document).on('click', '.gallery-image-form .delete-image', this.deletePhoto);
    };

    selectPhoto = (evt: any) => {
        const input = evt.target;
        const files = input.files;

        if (files && files[0]) {
            const reader = new FileReader();
            reader.onload = (e: any) => this.selectPhotoReady(input, e.target.result);
            reader.readAsDataURL(files[0]);
        }
    };

    selectPhotoReady = (input: any, image: string) => {
        const box = $(input).parents('.gallery-image-form.empty');
        box.find('img').attr('src', image);
        box.removeClass('empty');
        this.addNewImageBox();
    };

    deletePhoto = (evt: any) => {
        if (confirm('Are you sure you want to delete this image?')) {
            $(evt.target)
                .parents('.gallery-image-form')
                .remove();
        }
    };

    addNewImageBox = () => {
        if (!$('.gallery-image-form.empty').length) {
            $(this.template).insertAfter($('.gallery-image-form').last());
        }
    };
}
