import $ from 'jquery';

import storage from '../classes/Storage';

import LazyLoad from '../classes/LazyLoad';

import HomepageSearch from './HomepageSearch';

export default class Homepage {
    explorePeriods: any;
    explorePeriod: any;
    exploreCity: any;
    select: any;
    dropdownParent: any;
    exploreEventList: any;
    loadEventInCityUrl: any;
    loadEventInCityViewAllBtn: any;

    location = false as any;

    exploreSection: any;

    defaultExplore = {
        date: {text: 'in next week', period: 7},
        location: {
            city: 'London',
            name: 'London, Greater London, England, United Kingdom',
            longitude: '-0.118092',
            latitude: '51.509865',
        },
    };

    constructor() {
        if ($('.main-page-form').length) {
            new HomepageSearch();
           // this.initElement();
           // this.initEvent();
           // this.initLocation();
           // this.initEventInCityInPeriod();
        }
    }

    initEvent = () => {
        this.explorePeriods.click(this.changeExplorePeriod);
        this.select.select2({
            minimumInputLength: 2,
            dropdownParent: this.dropdownParent,
            templateResult: this.formatSuggestion,
            templateSelection: this.formatRepoSelection,
            ajax: {
                url: '/api/locations',
                dataType: 'json',
                delay: 250,
                processResults: this.processResults,
                placeholder: 'Search location',
                data: (params: any) => ({query: params.term}),
            },
        });

        this.exploreCity.click(() => {
            this.select.select2('open');
        });
        this.select.on('select2:select', this.selectedEvent);
    };

    initLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((evt: any) => {
                storage.setData('location', [evt.coords.longitude, evt.coords.latitude]);
            });
        }
    };

    initElement = () => {
        this.exploreSection = $('.section--explore_events');
        this.explorePeriods = $('.explore-periods');
        this.explorePeriod = $('.explore-period');
        this.exploreCity = $('.explore-city');
        this.exploreEventList = $('.explore-events-list');

        this.select = $('.explore-city-select');
        this.dropdownParent = $('.explore-city-container');
        this.loadEventInCityViewAllBtn = $('.btn-explore');
    };

    initEventInCityInPeriod = () => {
        this.loadEventInCityUrl = this.loadEventInCityViewAllBtn.attr('href');
        this.setExplorePeriod();
        this.setExploreLocation();
        this.setExploreViewAllUrl();
        this.loadExploreEvent();
    };

    selectedEvent = (e: any) => {
        storage.setData('exploreData.location', e.params.data);
        this.setExploreLocation();
        this.loadExploreEvent();
        this.setExploreViewAllUrl();
    };

    processResults = (data: any) => {
        data = this.location ? [this.location, ...data] : data;
        return {results: data.map((item: any, index: number) => ({...item, id: index}))};
    };

    formatSuggestion = (location: any) => {
        return location.name;
    };

    formatRepoSelection = (location: any) => {
        return location.name;
    };

    changeExplorePeriod = (evt: any) => {
        const el = $(evt.currentTarget);
        storage.setData('exploreData.date', {period: el.data('period'), text: el.data('text')});
        this.setExplorePeriod();
        this.loadExploreEvent();
        this.setExploreViewAllUrl();
    };

    loadExploreEvent = () => {
        const date = storage.getData('exploreData.date');
        const location = storage.getData('exploreData.location');
        this.exploreEventList.append("<div class='event-loader'></div>");
        const [after, before] = this.periodToTime(date.period);
        this.getBody(`/block-explore?location=${location.name}&after=${after}&before=${before}`).then(
            this.setExploreEventList,
        );
    };

    setExploreViewAllUrl = () => {
        const date = storage.getData('exploreData.date');
        const location = storage.getData('exploreData.location');
        if (location && date && this.loadEventInCityUrl) {
            this.exploreCity.text(location.city);
            const [after, before] = this.periodToTime(date.period);
            const url = this.loadEventInCityUrl
                .replace('LOCATION', `${location.name}`)
                .replace('AFTER', after)
                .replace('BEFORE', before);
            this.loadEventInCityViewAllBtn.attr('href', url);
        }
    };

    setExplorePeriod = () => {
        let date = storage.getData('exploreData.date');
        if (!date) {
            date = this.defaultExplore.date;
            storage.setData('exploreData.date', date);
        }
        this.explorePeriod.text(date.text.toLocaleLowerCase());
        this.explorePeriods.removeClass('active');
        this.explorePeriods.filter(`[data-period="${date.period}"]`).addClass('active');
    };

    setExploreEventList = (result: any) => {
        this.exploreEventList.html(result);
        LazyLoad.getInstance().update();
    };

    setExploreLocation = () => {
        let location = storage.getData('exploreData.location');
        if (!location) {
            location = this.defaultExplore.location;
            storage.setData('exploreData.location', location);
        }
        this.exploreCity.text(location.city);
    };

    periodToTime = (days: any) => {
        const start = new Date();
        const end = new Date();
        end.setDate(end.getDate() + parseInt(days));
        return [this.formatDate(start, 'Y-m-d'), this.formatDate(end, 'Y-m-d')];
    };

    formatDate = (date: Date, format: string) => {
        return format
            .replace('Y', `${date.getFullYear()}`)
            .replace('m', `${this.formatNumber(date.getMonth() + 1)}`)
            .replace('d', `${this.formatNumber(date.getDate())}`);
    };

    formatNumber = (number: number) => {
        if (number < 10) {
            return `0${number}`;
        }
        return `${number}`;
    };

    getData = (url = '') => {
        return fetch(url, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
            },
            redirect: 'follow', // manual, *follow, error
            referrer: 'no-referrer', // no-referrer, *client
        }).then(response => response.json()); // parses response to JSON
    };

    getBody = (url = '') => {
        return fetch(url, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            redirect: 'follow', // manual, *follow, error
            referrer: 'no-referrer', // no-referrer, *client
        }).then(response => response.text()); // parses response to JSON
    };
}
