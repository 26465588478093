import $ from 'jquery';

export default class EventBlock {
    readMoreBtn: any;
    MAX_HEIGHT = 170;
    CLOSE_CLASS = 'close-block';

    constructor() {
        this.initElement();
        this.initEvent();
        this.checkInitHeight();
        this.setMaxHeight();
    }

    setMaxHeight = () => {
        this.readMoreBtn.each((index: any, el: any) => {
            if (this.getElementBlock(el).hasClass(this.CLOSE_CLASS)) {
                const height = this.getElementContent(el).height() || 1000;
                this.getElementContentWrapper(el).css('max-height', height);
            } else {
                this.getElementContentWrapper(el).css('max-height', 'initial');
            }
        });
    };

    checkInitHeight = () => {
        this.readMoreBtn.each((index: any, el: any) => {
            const height = this.getElementContent(el).height() || 1000;
            if (height < this.MAX_HEIGHT) {
                this.openBlock(el);
            }
        });
    };

    initElement = () => {
        this.readMoreBtn = $('.event--block .read-more');
    };

    initEvent = () => {
        this.readMoreBtn.click((evt: any) => this.openBlock(evt.target));
        $(window).resize(this.setMaxHeight);
    };

    openBlock = (el: any) => {
        this.getElementBlock(el).removeClass(this.CLOSE_CLASS);
    };

    getElementBlock = (el: any) => {
        return $(el).parents('.event--block');
    };

    getElementContentWrapper = (el: any) => {
        return this.getElementBlock(el).find('.block-content-wrapper');
    };

    getElementContent = (el: any) => {
        return this.getElementBlock(el).find('p');
    };
}
