import Croppie from 'croppie';
import $ from 'jquery';

export default class ImagePicker {
    croppie: Croppie;
    checkIsCancel = false;

    private readonly popup: HTMLElement;
    private readonly editPhoto: HTMLElement;
    private readonly selectInput: HTMLInputElement;
    private readonly croppieWrapper: HTMLElement;
    private readonly cancelButton: HTMLElement;
    private readonly saveButton: HTMLElement;

    private photoPreview: HTMLElement;
    private formInput: HTMLInputElement;
    private readonly changeButton: HTMLElement;

    private modal: JQuery;

    constructor(editPhoto: HTMLElement) {
        this.popup = document.querySelector('.cropper-popup') as HTMLElement;
        this.editPhoto = editPhoto;
        this.modal = $(this.popup);

        this.selectInput = this.popup.querySelector('.cropper-input') as HTMLInputElement;
        this.croppieWrapper = this.popup.querySelector('.image-wrapper') as HTMLElement;
        this.saveButton = this.popup.querySelector('.cropper-save') as HTMLElement;
        this.cancelButton = this.popup.querySelector('.cropper-cancel') as HTMLElement;

        this.photoPreview = this.editPhoto.querySelector('.image-preview') as HTMLElement;
        this.changeButton = this.editPhoto.querySelector('.change-photo') as HTMLElement;
        this.formInput = this.editPhoto.querySelector('.user-avatar') as HTMLInputElement;

        this.croppie = new Croppie(this.croppieWrapper, {
            viewport: {
                width: parseInt(this.photoPreview.dataset.width || '200'),
                height: parseInt(this.photoPreview.dataset.width || '200'),
            },
            enableExif: true,
        });

        this.initEvent();
    }

    on = (element: HTMLElement, event: string, callback: any) => {
        if (element) {
            element.addEventListener(event, callback);
        }
    };

    cancelSelect = () => {
        const files = this.selectInput.files;

        if (this.checkIsCancel && (!files || !files.length)) {
            this.togglePopup(false);
        }
    };

    private initEvent = () => {
        document.body.onfocus = () => setTimeout(this.cancelSelect, 500);
        this.on(this.changeButton, 'click', this.openImage);
        this.on(this.photoPreview, 'click', this.openImage);
        this.on(this.selectInput, 'change', this.readFile);
        this.on(this.saveButton, 'click', this.crop);
        this.on(this.cancelButton, 'click', () => this.togglePopup(false));
    };

    private openImage = () => {
        this.selectInput.value = '';
        this.toggleVisiblePopup(false);
        this.togglePopup(true);
        setTimeout(() => this.selectInput.click(), 100);
    };

    private crop = () => {
        this.croppie
            .result({
                type: 'canvas',
                size: 'viewport',
            })
            .then((src: any) => {
                this.formInput.value = src;
                this.photoPreview.style.backgroundImage = `url(${src})`;
                this.togglePopup(false);
            });
    };

    private readFile = () => {
        this.checkIsCancel = false;
        const files = this.selectInput.files;

        if (files && files.length) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                this.toggleVisiblePopup(true);
                this.croppie.bind({
                    url: e.target.result,
                });
            };
            reader.readAsDataURL(files[0]);
        } else {
            this.togglePopup(false);
        }
    };

    private togglePopup = (show = true) => {
        if (show) {
            this.modal.modal('show');
            this.checkIsCancel = true;
        } else {
            this.modal.modal('hide');
            this.toggleVisiblePopup(false);
            this.checkIsCancel = false;
        }
    };

    private toggleVisiblePopup = (show = true) => {
        if (show) {
            this.modal.addClass('visible');
        } else {
            this.modal.removeClass('visible');
        }
    };
}
