import moment from 'moment';
import $ from 'jquery';
import flatpickr from 'flatpickr';

import ImagePicker from '../classes/ImagePicker';
import CategoryPicker from '../classes/CategoryPicker';

export default class ProfileEdit {
    constructor() {
        this.initElement();
        this.initChangeImage();
        $('.select-category-select').each((i: any, el: any) => {
            const select = $(el);
            if (!select.data('select2')) {
                new CategoryPicker(el);
            }
        });
    }

    initElement = () => {
        $('#user_timezone').select2({placeholder: 'Search', width: '100%'});
        $('#user_currency').select2({placeholder: 'Search', width: '100%'});
        flatpickr('.profile-birth-date', {
            maxDate: moment().format('YYYY-MM-DD'),
        });
    };

    initChangeImage = () => {
        const editPhoto = document.querySelector('.photo-edit') as HTMLElement;
        if (editPhoto) {
            new ImagePicker(editPhoto);
        }
    };
}
