import $ from 'jquery';

export default class SmoothScroll {
    anchors: any;

    constructor() {
        this.initElement();
        this.initEvent();
    }

    initElement = () => {
        this.anchors = $('a[href^="#_"]');
    };

    initEvent = () => {
        this.anchors.on('click', this.scrollTo);
    };

    scrollTo = (ev: any) => {
        ev.preventDefault();
        const href = ev.currentTarget.getAttribute('href');
        if (href.length > 1) {
            const container = document.querySelector(href.replace('_', ''));
            container &&
                container.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
        }
    };
}
