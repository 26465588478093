import lightpick from 'lightpick';

export default class DateFilter {
    container: HTMLElement;
    formattedInput: any;
    dateRangeInputs: HTMLInputElement[];
    picker: any;
    form?: any;

    constructor(container: HTMLElement, form?: HTMLFormElement) {
        this.dateRangeInputs = Array.from(container.querySelectorAll('input'));
        this.formattedInput = document.querySelector('#date-formatted');
        this.form = form;
        this.container = container;
        this.init();
    }

    init() {
        if (this.form !== undefined) {
            this.picker = new lightpick({
                parentEl: this.container,
                field: this.dateRangeInputs[0],
                secondField: this.dateRangeInputs[1],
                singleDate: false,
                numberOfMonths: window.innerWidth > 1050 ? 2 : 1,
                lang: 'en',
                format: 'YYYY-MM-DD',
                autoclose: false,
                footer: true,
            });

            let btn: any = this.container.querySelector('.lightpick__apply-action');
            const parent = btn.parentElement;
            parent.removeChild(btn);
            btn = document.createElement('div');
            btn.innerHTML = 'Apply';
            btn.classList.add('btn');
            if (this.form) {
                btn.classList.add('btn--cancel');
            } else {
                btn.classList.add('btn--dark');
            }
            parent.appendChild(btn);
            btn.addEventListener('click', () => {
                this.form.submit();
            });
        } else {
            this.dateRangeInputs.forEach((input: HTMLInputElement) => {
                input.classList.add('input-date--unstyled');
            });
            this.picker = new lightpick({
                parentEl: this.container,
                field: this.dateRangeInputs[0],
                secondField: this.dateRangeInputs[1],
                repick: true,
                singleDate: false,
                numberOfMonths: window.innerWidth > 1050 ? 2 : 1,
                lang: 'en',
                autoclose: true,
                format: 'YYYY-MM-DD',
                onSelect: this.displayFormattedDate,
                onOpen: () => this.reset(),
            });
        }
    }

    // #date-formatted

    // format date
    formatDate = (start: any, end: any) => {
        if (start && end) {
            if (start.year() !== end.year()) {
                return `${start.format('DD MMM YYYY')} - ${end.format('DD MMM YYYY')}`;
            }
            if (start.month() !== end.month()) {
                return `${start.format('DD MMM')}-${end.format('DD MMM YYYY')}`;
            }
            return `${start.format('DD')}-${end.format('DD MMM YYYY')}`;
        }
        return '';
    };

    displayFormattedDate = () => {
        const start = this.picker.getStartDate();
        const end = this.picker.getEndDate();
        if (this.formattedInput && start && end) {
            this.formattedInput.value = this.formatDate(start, end);
        }
    };

    reset = () => {
        this.picker.setDateRange('', '');
        if (this.formattedInput) {
            this.formattedInput.value = '';
        }
    };
}
