import $ from 'jquery';

export default class Menu {
    mobileMenu: any;
    navbarToggler: any;
    body: any;
    navbar: HTMLElement | any;

    SHOW_CLASS = 'show';
    OPEN_MENU_CLASS = 'open-menu';

    constructor() {
        this.initElement();
        this.initEvent();
    }

    initElement = () => {
        this.mobileMenu = $('.navbar-nav');
        this.navbarToggler = $('.navbar-toggler');
        this.body = $('body');
        //this.navbar = document.querySelector('.navbar');
    };

    initEvent = () => {
        this.navbarToggler.click(this.toggleMenu);
        this.scroll();
    };

    checkScrolled = () => {
        if (window.scrollY > 0) {
            //this.navbar.classList.add('scrolled');
        } else {
            //this.navbar.classList.remove('scrolled');
        }
    };

    scroll = () => {
        this.checkScrolled();
        document.addEventListener('scroll', () => {
            this.checkScrolled();
        });
    };

    toggleMenu = () => {
        if (this.mobileMenu.hasClass(this.SHOW_CLASS)) {
            this.closeMenu();
        } else {
            this.openMenu();
        }
    };

    openMenu = () => {
        this.mobileMenu.addClass(this.SHOW_CLASS);
        this.navbarToggler.addClass('open');
       // this.navbar.classList.add('menu-open');
        this.body.addClass(this.OPEN_MENU_CLASS);
    };

    closeMenu = () => {
        this.mobileMenu.removeClass(this.SHOW_CLASS);
        this.navbarToggler.removeClass('open');
        //this.navbar.classList.remove('menu-open');
        this.body.removeClass(this.OPEN_MENU_CLASS);
    };
}
