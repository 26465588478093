export type Category = {
    id: number;
    name: string;
    slug: string;
    order: number;
    nameAr:string;
}; 

export type CategoryMain = Category & {
    subcategories: Category[];
};

export default class Categories {
    static categories: CategoryMain[] = [];
    static resolveFunctions: any[] = [];
    static init = false;

    static getCategories = (callback: (categories: CategoryMain[]) => void) => {
        if (Categories.categories.length) {
            callback(Categories.categories);
        } else {
            Categories.resolveFunctions.push(callback);
        }
        if (!Categories.init) {
            Categories.init = true;
            Categories.fetchCategories();
        }
    };

    static resolve = () => {
        Categories.resolveFunctions.forEach(callback => {
            callback(Categories.categories);
        });
    };

    static fetchCategories = () => {
        fetch('/api/categories')
            .then(response => response.json())
            .then((categories: CategoryMain[]) => {
                Categories.categories = categories;
                Categories.resolve();
            });
    };
}
