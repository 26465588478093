export default class Review {
    constructor() {
        const form = document.querySelector('.add-review-form');
        if (form) {
            form.addEventListener('submit', this.submitting);
        }
    }

    submitting = (evt: Event) => {
        const form = evt.currentTarget as HTMLFormElement | null;
        if (form) {
            const formInner = form.querySelector('.review-create');
            if (formInner) {
                formInner.classList.add('loading');
            }
        }
    };
}
