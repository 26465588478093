export default class Pricing {
    pageClassName = 'pricing';
    animatedClassName = 'animated';
    visibleClassName = 'visible';
    hiddenClassName = 'hidden';
    animatedElements: NodeListOf<HTMLElement> | undefined;

    constructor() {
        if (document.body.classList.contains(this.pageClassName)) {
            this.animatedElements = document.querySelectorAll(`.${this.animatedClassName}`);
            this.initAnimatedClassNames();
            this.initHeadingAnimation();
        }
    }

    initAnimatedClassNames() {
        this.animatedElements = this.animatedElements as NodeListOf<HTMLElement>;
        this.animatedElements.forEach((element, index) => {
            if (index === 0) {
                element.classList.add(this.visibleClassName);
            } else {
                element.classList.add(this.hiddenClassName);
            }
        });
    }

    initHeadingAnimation() {
        setInterval(() => {
            this.animatedElements = this.animatedElements as NodeListOf<HTMLElement>;
            this.animatedElements.forEach((element, index, list) => {
                if (element.classList.contains(this.visibleClassName)) {
                    if (list[index + 1]) {
                        element.classList.remove(this.visibleClassName);
                        setTimeout(() => {
                            element.classList.add(this.hiddenClassName);
                            list[index + 1].classList.remove(this.hiddenClassName);
                            setTimeout(() => {
                                list[index + 1].classList.add(this.visibleClassName);
                            }, 300);
                        }, 300);
                    } else {
                        setTimeout(() => {
                            element.classList.remove(this.visibleClassName);
                            setTimeout(() => {
                                element.classList.add(this.hiddenClassName);
                                list[0].classList.remove(this.hiddenClassName);
                                setTimeout(() => {
                                    list[0].classList.add(this.visibleClassName);
                                }, 300);
                            }, 300);
                        }, 300);
                    }
                }
            });
        }, 5000);
    }
}
