import $, {Event} from 'jquery';
import {TooltipOption} from 'bootstrap';

import Cookie from '../classes/Cookie';

export default class Tooltip {
    option: TooltipOption = {
        placement: 'bottom',
        html: true,
    };

    constructor() {
        $('.with-tooltip')
            .tooltip(this.option)
            .filter('.showForce')
            .tooltip('show');

        $(document).on('click', '.tooltip-inner a', this.closeTooltip);
    }

    closeTooltip = (e: Event) => {
        if (e.target) {
            const id = $(e.target)
                .parents('.tooltip')
                .attr('id');
            const element = $(`[aria-describedby="${id}"]`);

            element
                .removeClass('showForce')
                .tooltip('dispose')
                .tooltip(this.option);

            Cookie.set(element.data('cookiekey'), 1);
        }
    };
}
