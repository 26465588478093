import '../scss/main.scss';

import AOS from 'aos';
import jQuery from 'jquery';

import Comment from './modules/Comment';
import CookieAccept from './modules/CookieAccept';
import Currency from './modules/Currency';
import EditGallery from './modules/EditGallery';
import EventBlock from './modules/EventBlock';
import EventGallery from './modules/EventGallery';
import EventMap from './modules/EventMap';
import EventStickyInfo from './modules/EventStickyInfo';
import FosComment from './modules/FosComment';
import Homepage from './modules/Homepage';
import Pricing from './modules/Pricing';
import Menu from './modules/Menu';
import NavigationSearch from './modules/NavigationSearch';
import ProfileEdit from './modules/ProfileEdit';
import ResultMap from './modules/ResultMap';
import Review from './modules/Review';
import SmoothScroll from './modules/SmoothScroll';
import Statistic from './modules/Statistic';
import LikeEvent from './classes/LikeEvent';
import FlashMessage from './modules/FlashMessage';
import LazyLoad from './classes/LazyLoad';
import NumberFormat from './classes/NumberFormat';
import ConfirmSubmit from './modules/ConfirmSubmit';
import Tooltip from './modules/Tooltip';

import 'bootstrap';
import 'daterangepicker';
import 'jquery-outside-events';
import 'lightbox2/dist/js/lightbox';
import 'pc-bootstrap4-datetimepicker';
import 'select2/dist/js/select2.full';
import 'slick-carousel';

AOS.init();
declare global {
    interface Window {
        currency: string;
        formSearchHtml: string;
        eventLatLan: any;
        $: any;
        jQuery: JQueryStatic;
        eventId: number | undefined;
    }
}

[
    Comment,
    CookieAccept,
    ConfirmSubmit,
    Currency,
    EditGallery,
    EventBlock,
    EventGallery,
    EventMap,
    EventStickyInfo,
    FlashMessage,
    FosComment,
    Homepage,
    Pricing,
    LazyLoad,
    LikeEvent,
    Menu,
    /*NavigationSearch,*/
    NumberFormat,
    ProfileEdit,
    ResultMap,
    Review,
    SmoothScroll,
    Statistic,
    Tooltip
].forEach(module => new module());

window.jQuery = jQuery;
window.$ = jQuery;
