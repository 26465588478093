export default class FlashMessage {
    constructor() {
        const closeMsgButtons = document.querySelectorAll('.message > .close-block');
        [...closeMsgButtons].forEach(button => {
            button.addEventListener('click', () => {
                if (button.parentNode && button.parentNode.parentNode) {
                    button.parentNode.parentNode.removeChild(button.parentNode);
                }
            });
        });
    }
}
